import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { config } from 'config';
import { StateProvider as CustomPageLivePreviewStateProvider, LivePreview as CustomPageLivePreview } from 'custom-page';
import { TVScreen } from 'data-store';
import { StateProvider as ProductWallLivePreviewStateProvider, LivePreview as ProductWallLivePreview } from 'product-wall';
import { generateFakeOpeningHours, PharmacyOnDutyNearby } from 'utils';

import styles from './SlideshowViewer.scss';

export default function LivePreview(props: {
    locale: Domain.Locale;
    screenResolution: Domain.DeviceScreenResolution;
    getPharmaciesOnDutyNearby: (numPharmacists: number, locale: string) => PharmacyOnDutyNearby[];
    getEmergencyAlerts: (locale: string) => Domain.BeAlert[];
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    branchId?: string;
    enableStockDisplay?: boolean;
}) {
    const message = useSelector(TVScreen.livePreviewState.selectLivePreviewMessage);

    const getMediaItemURL = (mediaItemId: string | undefined) =>
        mediaItemId ? `${config.apiBaseUrl}/media-item/${mediaItemId}/preview` : '';
    const getVideoMediaItemPreviewURL = (mediaItemId: string | undefined) =>
        mediaItemId ? `${config.apiBaseUrl}/media-item/${mediaItemId}/preview/450x450` : '';

    return (
        <div className={`${styles.SlideshowViewer} ${styles.LivePreview}`}>
            {message && message.type === 'customPage' ? (
                <CustomPageLivePreviewStateProvider
                    layout={message.payload.data}
                    availableProducts={message.payload.availableProducts || []}
                    screenResolution={props.screenResolution}
                >
                    <CustomPageLivePreview
                        gettext={text => text}
                        proxyImageURL={url => url}
                        getMediaItemURL={getMediaItemURL}
                        getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                        autoPlayVideos={true}
                        disableVideoPosters={true}
                        renderVideo={props.renderVideo}
                        loopVideos={true}
                        onProductClick={() => null}
                        onHiddenSlideClick={() => null}
                        getPharmaciesOnDutyNearby={props.getPharmaciesOnDutyNearby}
                        getOpeningHours={generateFakeOpeningHours}
                        getEmergencyAlerts={props.getEmergencyAlerts}
                        locale={props.locale}
                    />
                </CustomPageLivePreviewStateProvider>
            ) : null}

            {message && message.type === 'productWall' ? (
                <ProductWallLivePreviewStateProvider
                    layout={message.payload.data}
                    stock={{}}
                    availableProducts={message.payload.availableProducts || []}
                    screenResolution={props.screenResolution}
                >
                    <ProductWallLivePreview
                        proxyImageURL={url => url}
                        getMediaItemURL={getMediaItemURL}
                        getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                        autoPlayVideos={true}
                        disableVideoPosters={true}
                        loopVideos={true}
                        renderVideo={props.renderVideo}
                        onProductClick={() => null}
                        locale={props.locale}
                        enableStockDisplay={!!props.enableStockDisplay}
                    />
                </ProductWallLivePreviewStateProvider>
            ) : null}
        </div>
    );
}

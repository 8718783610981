import * as React from 'react';

import { keyboardLayout } from 'utils';

export type RenderKeyProps = {
    id: string;
    label: string;
    defaultKeyWidth: 'small' | 1 | 2 | 3;
    onPress: () => void;
    active?: boolean;
    disabled?: boolean;
};

export type RenderKeyFn = ({ id, label, defaultKeyWidth, onPress, active, disabled }: RenderKeyProps) => React.ReactNode;

export type OnScreenKeyboardProps = {
    layout: 'letters' | 'digits-and-letters' | 'email' | 'phone' | 'number' | 'brand-name' | 'pin';
    locale: string;
    linkedInputRef?: React.RefObject<HTMLInputElement | null | undefined>;
    onKeyPress?: (key: string) => void;
    activeKey?: string;
    disabledKeys?: string[];
    renderKey: RenderKeyFn;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onKeyPress'>;

export default function OnScreenKeyboard({
    layout,
    locale,
    linkedInputRef,
    onKeyPress,
    activeKey,
    disabledKeys,
    renderKey,
    ...rest
}: OnScreenKeyboardProps) {
    const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let lastRowLetterKeys = ['Z', 'X', 'C', 'V', 'B', 'N'];
    let letterKeys = [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
        [...lastRowLetterKeys, '-space-', '-backspace-'],
    ];

    if (keyboardLayout(locale) === 'azerty') {
        lastRowLetterKeys = ['W', 'X', 'C', 'V', 'B', 'N'];
        letterKeys = [
            ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
            [...lastRowLetterKeys, '-space-', '-backspace-'],
        ];
    }

    let keys: string[][] = [];
    let keyWidth: 1 | 2 = 1;

    if (layout === 'letters') {
        keys = letterKeys;
    } else if (layout === 'digits-and-letters') {
        keys = [digits, ...letterKeys];
    } else if (layout === 'email') {
        keys = [digits, letterKeys[0], letterKeys[1], [...lastRowLetterKeys, '@', '-', '_', '.'], ['-filler-', '-backspace-']];
    } else if (layout === 'number') {
        keys = [
            ['1', '2', '3', '4', '5'],
            ['6', '7', '8', '9', '0'],
            ['-filler-', '-backspace-'],
        ];
        keyWidth = 2;
    } else if (layout === 'phone') {
        keys = [
            ['1', '2', '3', '4', '5'],
            ['6', '7', '8', '9', '0'],
            ['+', '-filler-', '-backspace-'],
        ];
        keyWidth = 2;
    } else if (layout === 'brand-name') {
        keys = [
            digits,
            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
            ['K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'],
            ['U', 'V', 'W', 'X', 'Y', 'Z', '-filler-', '-filler-', '-filler-', '-filler-'],
        ];
        keyWidth = 1;
    } else if (layout === 'pin') {
        keys = [
            ['1', '2', '3'],
            ['4', '5', '6'],
            ['7', '8', '9'],
            ['-clear-', '0', '-backspace-'],
        ];
        keyWidth = 1;
    }

    const dispatchInputChangeEvent = (newValue: string) => {
        if (!linkedInputRef || !linkedInputRef.current) {
            return;
        }

        const event = new Event('input', { bubbles: true });
        // @ts-ignore
        const setValue = Object.getOwnPropertyDescriptor(linkedInputRef.current.__proto__, 'value').set;
        (setValue as any).call(linkedInputRef.current, newValue);
        linkedInputRef.current.dispatchEvent(event);
    };

    const handleKeyPress = (key: string) => {
        if (onKeyPress) {
            onKeyPress(key);
        }

        if (key === '-backspace-') {
            handleBackspacePress();
            return;
        }

        if (!linkedInputRef || !linkedInputRef.current) {
            return;
        }

        let keyValue = key;
        if (keyValue === '-space-') {
            keyValue = ' ';
        }
        if (key === '-clear-') {
            handleClearAll();
            return;
        }
        let newValue = linkedInputRef.current.value;
        let caretPosition = newValue.length;

        if (linkedInputRef.current.selectionStart !== null) {
            caretPosition = linkedInputRef.current.selectionStart;
        }

        if (caretPosition === 0) {
            newValue = keyValue + newValue;
        } else {
            newValue = [newValue.slice(0, caretPosition), keyValue, newValue.slice(caretPosition)].join('');
        }

        dispatchInputChangeEvent(newValue);

        linkedInputRef.current.focus();
        setTimeout(() => {
            if (linkedInputRef && linkedInputRef.current) {
                let newCaretPosition = caretPosition + 1;
                if (newCaretPosition > newValue.length) {
                    newCaretPosition = newValue.length;
                }
                linkedInputRef.current.setSelectionRange(newCaretPosition, newCaretPosition);
            }
        }, 10);
    };

    const handleBackspacePress = () => {
        if (!linkedInputRef || !linkedInputRef.current) {
            return;
        }

        let newValue = linkedInputRef.current.value;
        let caretPosition = newValue.length;

        if (linkedInputRef.current.selectionStart !== null) {
            caretPosition = linkedInputRef.current.selectionStart;
        }

        if (caretPosition === 0) {
            linkedInputRef.current.focus();

            return;
        }

        newValue = [newValue.slice(0, caretPosition - 1), newValue.slice(caretPosition)].join('');

        dispatchInputChangeEvent(newValue);

        linkedInputRef.current.focus();
        setTimeout(() => {
            if (linkedInputRef && linkedInputRef.current) {
                let newCaretPosition = caretPosition - 1;
                if (newCaretPosition < 0) {
                    newCaretPosition = 0;
                }

                linkedInputRef.current.setSelectionRange(newCaretPosition, newCaretPosition);
            }
        }, 10);
    };
    const handleClearAll = () => {
        if (!linkedInputRef || !linkedInputRef.current) {
            return;
        }

        dispatchInputChangeEvent('');
    };
    return (
        <div {...rest}>
            {keys.map((row, rowIndex) => {
                return (
                    <div key={rowIndex}>
                        {row.map((key, keyIndex) => {
                            return renderKey({
                                id: key + '-' + rowIndex + '-' + keyIndex,
                                label: key,
                                disabled: disabledKeys && disabledKeys.indexOf(key) > -1,
                                defaultKeyWidth: keyWidth,
                                onPress: () => handleKeyPress(key),
                                active: key === activeKey,
                            });
                        })}
                    </div>
                );
            })}
        </div>
    );
}
